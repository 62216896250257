<template>
  <shipment-dialog
    :value="value"
    modal-class="shipment-proposals-decline-dialog"
    @input="toggle"
  >
    <div class="shipment-proposals-decline-dialog__content">
      <h1 class="tw-flex shipment-proposals-decline-dialog__content__title mb-3 tw-font-normal">
        <ui-ctk-icon
          name="bid-2"
          data-test="icon"
        />
        <span>
          {{ $t('shipments.titles.proposals.decline') }}
        </span>
      </h1>

      <!-- Details -->
      <shipment-proposals-decline-dialog-details
        v-if="!declined"
        :shipment="shipment"
        :proposal="proposal"
      />

      <!-- Success -->
      <shipment-proposals-decline-dialog-success
        v-if="declined"
        :shipment="shipment"
        :proposal="proposal"
      />
    </div>

    <template #footer>
      <div
        class="md:tw-px-4 tw-flex tw-flex-col-reverse md:tw-flex-row tw-flex-1 tw-justify-between shipment-proposals-decline-dialog__footer"
      >
        <ui-button
          type="button"
          variant="link"
          class="shipment-proposals-decline-dialog__footer__cancel tw-mt-4 md:tw-mt-0"
          @click="toggle(false)"
        >
          {{ $t(declined
            ? 'shipments.buttons.close'
            : 'shipments.buttons.proposals.cancel') }}
        </ui-button>

        <ui-button
          v-if="!declined"
          :loading="$wait.is(loaderKey)"
          variant="danger"
          data-test="decline-proposal"
          @click="decline"
        >
          {{ $t('shipments.buttons.proposals.decline_proposal') | capitalize }}
        </ui-button>
      </div>
    </template>
  </shipment-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import { ShipmentProposal } from '@/resources'

  import ShipmentDialog from '@/views/Shippers/components/ShipmentDialog/index.vue'
  import ShipmentProposalsDeclineDialogDetails from './_subs/ShipmentProposalsDeclineDialogDetails/index.vue'
  import ShipmentProposalsDeclineDialogSuccess from './_subs/ShipmentProposalsDeclineDialogSuccess/index.vue'
  import { showToaster } from '@/services/Toaster'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - shipmentProposalsDeclineDialog
   * @param {object} shipment
   * @param {object} proposal
   */
  export default defineComponent({
    name: 'ShipmentProposalsDeclineDialog',
    components: {
      ShipmentDialog,
      ShipmentProposalsDeclineDialogDetails,
      ShipmentProposalsDeclineDialogSuccess
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        declined: false
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      // @ts-ignore
      loaderKey () {
        // @ts-ignore
        return `refusing proposal ${this.proposal.uuid}`
      }
    },
    methods: {
      /**
       * Close the current dialog
       * @function toggle
       */
      // @ts-ignore
      toggle (v) {
        if (this.declined && !v) {
          EventBus.$emit('shipment:refresh')
        }

        this.declined = false
        this.$emit('input', v)
      },
      /**
       * Declines the current proposal
       * @function decline
       */
      decline () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Confirmed Proposal Refusal', this.shipment.uuid)
        }

        if (this.$wait.is(this.loaderKey)) return

        this.$wait.start(this.loaderKey)
        ShipmentProposal.refuse({
          cid: this.getCid,
          sid: this.shipment.uuid,
          pid: this.proposal.uuid
        }, {})
          .then(() => {
            this.declined = true
          })
          .catch(err => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              const errorMessage = data.error.detail || data.error.title
              showToaster(this, errorMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
          .finally(() => this.$wait.end(this.loaderKey))
      }
    }
  })
</script>

<style lang="scss">

  .shipment-proposals-decline-dialog {
    &__content {
      padding: 32px;

      &__title {
        position: relative;
        font-size: 22px;
        left: -8px;

        span,
        .ctk-font {
          margin: auto 0;
        }

        .ctk-font {
          color: $background-danger;
        }
      }
    }
  }

</style>

<template>
  <UiLink
    :to="to"
    standalone
    class="ctk-view-back-button"
  >
    <template #left-icon>
      <UiMaterialIcon
        class="tw-mr-2 tw-text-lg"
        name="keyboard_arrow_left"
        data-test="icon"
      />
    </template>
    <span
      v-text="text"
      data-test="text"
    />
  </UiLink>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - CtkViewBackButton
   * @param {object} to
   * @param {string} text
   */
  export default defineComponent({
    props: {
      to: {
        type: Object,
        required: true
      },
      text: {
        type: String,
        required: true
      }
    }
  })
</script>

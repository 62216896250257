<template>
  <div class="shipment-expirdation-date-area">
    <ui-button
      v-if="isAvailable || isAwaitingAcceptation"
      variant="info"
      type="button"
      class="tw-w-full"
      v-matomo="{
        category: 'Shipments',
        action: 'Initiated Expiration Change',
        name: getCurrentShipment.uuid
      }"
      data-test="expiration-btn"
      @click="openDialog"
    >
      {{ $t('shipment.buttons.modify_expiration_delay') }}
    </ui-button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'

  export default {
    name: 'ShipmentExpirdationDateArea',
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      isAvailable () {
        return this.getCurrentShipment.state === 'available'
      },
      isAwaitingAcceptation () {
        return this.getCurrentShipment.state === 'awaiting_acceptation'
      }
    },
    methods: {
      openDialog () {
        EventBus.$emit('shipment:open-dialog', 'expiresAt')
      }
    }
  }
</script>

<template>
  <shipment-dialog
    :value="value"
    modal-class="shipment-update-expiration-date-dialog"
    id="shipment-update-expiration-date-dialog"
    @input="close"
  >
    <div class="shipment-update-expiration-date-dialog__content tw-flex tw-flex-1">
      <div class="tw-flex-1 py-5 tw-flex tw-items-center tw-justify-center tw-flex-1">
        <ValidationObserver
          ref="observer"
        >
          <form
            class="tw-w-full"
            @submit.prevent="submitted"
          >
            <h3
              class="tw-font-normal mb-4 fs-22"
              data-test="dialog-title"
            >
              {{ $t('shipment.dialogs.modify_expiration_delay.title') }}
            </h3>
            <p
              data-test="paragraph"
            >
              {{ $t('shipment.values.expiration_delay.description') }}
            </p>

            <i18n
              path="shipment.values.expiration_delay.explanation"
              data-test="expiration-delay-explanation"
              tag="p"
              class="mb-4"
            >
              <template #date>
                <span
                  class="tw-font-medium dots-text"
                >{{ maxExpirationDateFormatted }}</span>
              </template>
            </i18n>

            <ValidationProvider
              v-if="value"
              ref="date"
              :name="$t('shipment.labels.expiration_date')"
              rules="required"
              slim
            >
              <template slot-scope="{ errors, invalid }">
                <ctk-date-time-picker
                  ref="expiration-date"
                  id="expiration-date-input"
                  v-model="date"
                  name="expiration-date"
                  :label="$t('shipment.labels.expiration_date') | capitalize"
                  :error="invalid || !!expirationDateError"
                  :hint="errors[0]"
                  :loader="$wait.is('expiration date updating')"
                  :locale="$i18n.locale"
                  :disabled="$wait.is('expiration date updating')"
                  :behaviour="{
                    time: {
                      nearestIfDisabled: false
                    }
                  }"
                  color="#021D49"
                  button-color="#021D49"
                  format="YYYY-MM-DDTHH:mm:ss"
                  formatted="ddd LL LT"
                  position="top"
                  :min-date="minExpirationDate"
                  no-button-now
                  data-test="datepicker"
                />
              </template>
            </ValidationProvider>
            <p class="tw-text-red-500 mt-2">
              {{ expirationDateError }}
            </p>
          </form>
        </ValidationObserver>
      </div>
      <div class="pl-5 pt-5 pr-0 illustration-container tw-flex-1">
        <picture>
          <source
            srcset="~@/assets/img/illustration_reference_dialog.webp"
            type="image/webp"
          >
          <source
            srcset="~@/assets/img/illustration_reference_dialog.svg 2x"
            type="image/svg"
          >
          <img
            src="~@/assets/img/illustration_reference_dialog.png"
            class="tw-max-w-full"
            alt=""
          >
        </picture>
      </div>
    </div>

    <template #footer>
      <div
        class="shipment-update-expiration-date-dialog__footer tw-flex tw-justify-between tw-items-center tw-w-full px-3"
      >
        <ui-button
          variant="link"
          class="shipment-update-expiration-date-dialog__footer__cancel"
          data-test="close-button"
          @click="close"
        >
          {{ $t('shipments.buttons.close') }}
        </ui-button>

        <ui-button
          :loading="$wait.is('expiration date updating')"
          :disabled="$wait.is('expiration date updating')"
          variant="primary"
          class="shipment-update-expiration-date-dialog__footer__valid"
          data-test="validate"
          @click="submitted"
        >
          {{ $t('shipment.buttons.confirm_expiration_delay') | capitalize }}
        </ui-button>
      </div>
    </template>
  </shipment-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

  import { Shipment } from '@/resources'
  import { showToaster } from '@/services/Toaster'

  import ShipmentDialog from '@/views/Shippers/components/ShipmentDialog/index.vue'

  /**
   * @module component - shipmentUpdateExpirationDateDialog
   */
  export default {
    name: 'ShipmentUpdateExpirationDateDialog',
    components: {
      ShipmentDialog,
      CtkDateTimePicker
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        date: this.$moment(this.shipment.expires_at).format() || null,
        expirationDateError: null
      }
    },
    computed: {
      ...mapGetters('auth', ['getCid']),
      minExpirationDate () {
        return this.$moment().add(15, 'minutes').format()
      },
      maxExpirationDateFormatted () {
        return this.$moment(
          `${this.shipment.pickup.time_slot.date} ${this.shipment.pickup.time_slot.start_time}`
        ).subtract(15, 'minutes').format('L LT')
      }
    },
    methods: {
      ...mapActions('shipments', ['setShipmentData']),
      close () {
        /**
         * Remove the query param from the notification tab if it's present
         */
        if (this.$route.query['update-expires-at']) {
          this.$router.replace({
            ...this.$route,
            query: {}
          }).catch(() => {})
        }

        this.$emit('input', false)
      },
      submitted () {
        if (this.$matomo) {
          const { uuid } = this.shipment
          this.$matomo.trackEvent('Shipments', 'Confirmed Expiration Change', uuid)
        }

        this.$refs.observer.validate()
          .then(valid => {
            this.expirationDateError = null
            if (!valid || this.$wait.is('expiration date updating')) return
            this.$wait.start('expiration date updating')
            const payload = {
              date: this.$moment(this.date).format()
            }
            const data = {
              cid: this.getCid,
              sid: this.shipment.uuid
            }
            Shipment.expirationDate(data, payload)
              .then(() => {
                this.close()
                this.setShipmentData({
                  uuid: this.shipment.uuid,
                  data: {
                    expires_at: this.date
                  }
                })

                showToaster(
                  this,
                  this.$t('shipment.paragraphs.expiration_date_updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error) {
                  this.expirationDateError = data.error.detail || data.error.title
                }
              })
              .finally(() => this.$wait.end('expiration date updating'))
          })
      }
    }
  }
</script>

<style lang="scss">

  .shipment-update-expiration-date-dialog {
    &__content {
      form {
        padding-left: 32px;

        @media only screen and (max-width: $breakpoint-mobile-l) {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      &__footer {
        flex-direction: column-reverse;
        padding: 0 !important;

        &__cancel {
          margin-top: 16px;
        }
      }
    }

    .modal-container {
      overflow: inherit;
    }

    .illustration-container {
      position: relative;

      img {
        opacity: 0.8;
      }
    }

    @media screen and (max-width: $breakpoint-mobile-l) {
      .illustration-container {
        display: none;
      }
    }
  }

</style>

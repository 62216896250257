<template>
  <section
    id="price-section"
    class="shipment-price-detail tw-p-12 tw-bg-white tw-border tw-border-solid tw-border-gray-400 tw-rounded"
  >
    <div class="tw-flex tw-mb-3">
      <h4
        v-text="$t('shipment.titles.price_detail')"
        class="tw-flex-1"
        data-test="title"
      />

      <div class="shipment-price-detail__total tw-flex tw-items-start">
        <div class="tw-text-right">
          <CtkSkeletonBlock
            :loading="$wait.is('fetching shipment')"
            background="#287696"
            width="100px"
            height="40px"
            level="1"
          >
            <PriceChip
              v-text="$t('price_excl_tax', {
                price: $options.filters.currency(price, currency, $i18n.locale)
              })"
              class="price-total-price-container fs-16"
              data-test="price-without-tax"
            />
          </CtkSkeletonBlock>
          <CtkSkeletonBlock
            :loading="$wait.is('fetching shipment')"
            width="70px"
            height="20px"
            level="2"
          >
            <span
              v-if="vat_included_price"
              v-text="$t('price_incl_tax', {
                price: $options.filters.currency(vat_included_price, currency, $i18n.locale)
              })"
              class="price-total-price-tax tw-mt-2 tw-text-blue-500"
              data-test="price-with-tax"
            />
          </CtkSkeletonBlock>
        </div>
        <UiButton
          :title="isOpen
            ? $t('shipment.labels.hide_price_detail')
            : $t('shipment.labels.show_price_detail')"
          v-b-tooltip.hover
          variant="link"
          class="tw-ml-2"
          @click="isOpen = !isOpen"
          data-test="toggle-button"
        >
          <template #left-icon>
            <UiMaterialIcon
              :class="{
                'tw-transform tw-rotate-180': isOpen
              }"
              class="tw-transition-transform"
              name="keyboard_arrow_down"
              data-test="icon"
            />
          </template>
        </UiButton>
      </div>
    </div>

    <div class="shipment-price-detail__content">
      <div class="tw-flex-1 mr-4 tw-w-full mb-3">
        <BCollapse
          :id="'price-detail'"
          v-model="isOpen"
          role="tabpanel"
        >
          <template v-if="$wait.is('fetching shipment')">
            <CtkSkeletonView
              class="tw-flex tw-justify-between mb-2"
              depth="1"
            >
              <CtkSkeletonBlock
                width="200px"
                height="35px"
                level="2"
                loading
              />
              <CtkSkeletonBlock
                width="80px"
                height="35px"
                level="1"
                loading
              />
            </CtkSkeletonView>
            <CtkSkeletonView
              class="tw-flex tw-justify-between mb-2"
              depth="2"
            >
              <CtkSkeletonBlock
                width="180px"
                height="35px"
                level="2"
                loading
              />
              <CtkSkeletonBlock
                width="75px"
                height="35px"
                level="1"
                loading
              />
            </CtkSkeletonView>
            <CtkSkeletonView
              class="tw-flex tw-justify-between mb-2"
              depth="3"
            >
              <CtkSkeletonBlock
                width="200px"
                height="35px"
                level="2"
                loading
              />
              <CtkSkeletonBlock
                width="80px"
                height="35px"
                level="1"
                loading
              />
            </CtkSkeletonView>
          </template>
          <template v-else>
            <PricingLayout>
              <PricingLines
                v-for="line in priceLines"
                :key="line.key"
                :label="line.label"
                :cancelled="line.is_cancelled"
                :comment="line.comment"
              >
                <span class="tw-text-blue-500">
                  {{ line.price | currency(currency, $i18n.locale) }}
                </span>
              </PricingLines>
              <PricingLines
                v-if="hasTailLiftLine"
                :label="$t('app.paragraphs.hatch_included')"
                data-test="tail_lift"
              >
                <span
                  class="tw-text-blue-500"
                  v-text="$t('app.labels.included')"
                />
              </PricingLines>
              <PricingLines
                v-if="hasHandling('pickup')"
                :label="$t('app.paragraphs.pickup_handling_included')"
                data-test="handling-pickup"
              >
                <span
                  class="tw-text-blue-500"
                  v-text="$t('app.labels.included')"
                />
              </PricingLines>
              <PricingLines
                v-if="hasHandling('delivery')"
                :label="$t('app.paragraphs.delivery_handling_included')"
                data-test="handling-delivery"
              >
                <span
                  class="tw-text-blue-500"
                  v-text="$t('app.labels.included')"
                />
              </PricingLines>
            </PricingLayout>
          </template>
        </BCollapse>
      </div>

      <div
        v-if="hasPriceExplanation"
        class="shipment-price-detail__content__explanation tw-flex tw-items-center tw-w-full tw-flex-1 tw-rounded"
      >
        <CtkSkeletonBlock
          :loading="$wait.is('fetching shipment')"
          width="200px"
          height="70px"
          level="2"
          class="tw-flex-1"
        >
          <template
            v-if="(state === 'cancelled' || state === 'expired')"
          >
            <i18n
              v-if="!!getCurrentShipment.mission"
              class="my-4 ml-4 mb-0 tw-flex-1"
              data-test="cancellation-fees"
              path="shipment.paragraphs.price_cancellation_with_fees"
              tag="p"
            >
              <template #tos>
                <UiLink
                  :href="tos"
                  v-text="$t('shipment.labels.tos')"
                />
              </template>
            </i18n>
            <i18n
              v-if="!getCurrentShipment.mission && !hasInvoices"
              class="my-4 ml-4 mb-0 tw-flex-1"
              data-test="cancellation-without-fees"
              path="shipment.paragraphs.price_cancellation_without_fees"
              tag="p"
            >
              <template #tos>
                <UiLink
                  :href="tos"
                  v-text="$t('shipment.labels.tos')"
                />
              </template>
            </i18n>
          </template>

          <div
            v-if="state === 'available'"
            class="tw-p-8 tw-flex-1"
            data-test="available"
          >
            <p
              v-text="$t('shipment.paragraphs.price_detail.explanation_commitment')"
            />
            <p
              class="tw-mb-0"
              v-text="$t('shipment.paragraphs.price_detail.explanation_cancel')"
            />
          </div>
          <p
            v-if="(isInProgress || state === 'planned') && !hasInvoices"
            v-text="$t('shipment.paragraphs.price_in_progress_explanation')"
            class="my-4 ml-4 tw-mb-0 tw-flex-1"
            data-test="in_progress"
          />
          <p
            v-if="isFinished"
            v-text="$t('shipment.paragraphs.price_finished_explanation')"
            class="my-4 ml-4 tw-mb-0 tw-flex-1"
            data-test="finished"
          />
        </CtkSkeletonBlock>

        <img
          src="~@/assets/img/illustrations/price.svg"
          alt=""
          width="200"
          height="95"
          class="tw-flex-grow-0"
        >
      </div>
    </div>

    <!-- Booking prices -->
    <div
      v-if="bookings.length > 0"
      class="tw-mt-6"
    >
      <ShipmentPriceDetailBooking
        v-for="booking in bookings"
        :key="booking.uuid"
        :booking="booking"
        class="not-last:tw-mb-4"
      />
    </div>
  </section>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import Config from '@/services/Config'

  import CtkSkeletonView from '@/components/CtkSkeletonView/index.vue'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'
  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import PricingLines from '@/components/CtkPricing/_subs/PricingLines/index.vue'
  import ShipmentPriceDetailBooking from './_subs/ShipmentPriceDetailBooking/index.vue'
  import PriceChip from '@/components/CtkPricing/_subs/PriceTotal/_subs/PriceChip/index.vue'

  /**
   * @module component - ShipmentPriceDetail
   */
  export default defineComponent({
    name: 'ShipmentPriceDetail',
    components: {
      CtkSkeletonView,
      CtkSkeletonBlock,
      PriceChip,
      PricingLayout,
      PricingLines,
      ShipmentPriceDetailBooking
    },
    data () {
      return {
        isOpen: false,
        tos: Config.get('wwwBase') + 'goto/tos'
      }
    },
    computed: {
      ...mapGetters('auth', ['isFrenchShipper']),
      ...mapGetters('shipments', ['getCurrentShipment']),
      /**
       * Returns true if the whole explanation paragraph should be shown
       * @function hasPriceExplanation
       * @returns {boolean}
       */
      hasPriceExplanation () {
        const isCancelled = ['cancelled', 'expired'].includes(this.state)
        const hasMission = !!this.getCurrentShipment.mission

        const hasCancellationFees = isCancelled && hasMission
        const hasCancellationWithoutFees = isCancelled && !hasMission && !this.hasInvoices
        const isAvailable = this.state === 'available'
        const isInProgress = (this.isInProgress || this.state === 'planned') && !this.hasInvoices

        return hasCancellationFees || hasCancellationWithoutFees || isAvailable || isInProgress || this.isFinished
      },
      /**
       * Returns true if the shipment has a current booking active.
       * Since the bookings value from the currentShipment is async fetched,
       * it may have a delay before showing the real value.
       * TODO: Waits the GET booking before showing the view OR
       * TODO: Have amounts in the mission object, in the shipment
       * @function hasBooking
       * @returns {boolean}
       */
      hasBooking () {
        const { mission, bookings } = this.getCurrentShipment
        if (bookings && mission) {
          const currentBooking = bookings.findIndex(booking => booking && booking.uuid === mission.uuid)
          return currentBooking !== -1
        }

        return false
      },
      /**
       * @function hasInvoices
       * @returns {boolean}
       */
      hasInvoices () {
        const { billing } = this.getCurrentShipment
        return billing && billing.invoice_counts && billing.invoice_counts.total > 0
      },
      /**
       * @function isPalletNetwork
       * @returns {boolean}
       */
      isPalletNetwork () {
        return this.getCurrentShipment.price_type === 'pallet_network'
      },
      /**
       * Returns true if the additional tail lift price line should be shown
       * @function hasTailLiftLine
       * @returns {boolean}
       */
      hasTailLiftLine () {
        const { pickup, delivery } = this.getCurrentShipment
        const hasTailLift = pickup.handling.tail_lift || delivery.handling.tail_lift

        return this.isPalletNetwork && hasTailLift
      },
      hasHandling () {
        return direction => this.getCurrentShipment[direction].handling.driver && this.isPalletNetwork
      },
      /**
       * Returns the current booking
       * @function currentBooking
       * @returns {object} booking
       */
      currentBooking () {
        const { mission, bookings } = this.getCurrentShipment
        if (bookings && mission) {
          return bookings.find(booking => booking.uuid === mission.uuid)
        }

        return null
      },
      /**
       * Returns a list of bookings, not including the current one
       * @function bookings
       */
      bookings () {
        const { mission, bookings } = this.getCurrentShipment
        if (bookings && mission) {
          return bookings.filter(booking => booking.uuid !== mission.uuid)
        }

        return []
      },
      /**
       * Returns the offer price lines, if there is no mission.
       * Otherwise returns the booking price lines.
       * @function priceLines
       * @returns {Array}
       */
      priceLines () {
        return this.hasBooking
          ? this.currentBooking.billing.lines
          : this.getCurrentShipment.offer_price.price_lines
      },
      currency () {
        return this.hasBooking
          ? this.currentBooking.billing.currency
          : this.getCurrentShipment.offer_price.currency
      },
      price () {
        return this.hasBooking
          ? this.currentBooking.billing.price
          : this.getCurrentShipment.offer_price.price
      },
      vat_included_price () {
        return this.hasBooking
          ? this.currentBooking.billing.vat_included_price
          : this.getCurrentShipment.offer_price.vat_included_price
      },
      /**
       * @function state
       * @returns {string} state
       */
      state () {
        return this.getCurrentShipment.state
      },
      /**
       * Returns true if the shipment is "in progress"
       * @function isInProgress
       * @returns {boolean}
       */
      isInProgress () {
        const inProgressStates = ['started', 'transit', 'near_delivery', 'delivered']
        return inProgressStates.includes(this.state)
      },
      /**
       * @function isFinished
       * @returns {boolean}
       */
      isFinished () {
        return ['finished', 'proof_of_delivery_available'].includes(this.state)
      }
    }
  })
</script>

<style lang="scss" scoped>
.shipment-price-detail__content {
  align-items: flex-start;
}
.shipment-price-detail__content .pricing-layout {
  --tw-border-opacity: 1;
  border-top-color: rgba(39, 84, 145, var(--tw-border-opacity));
  border-style: solid;
  border-width: 0px;
  border-top-width: 1px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0.5rem;
}
.shipment-price-detail__content .pricing-lines {
  border-width: 0px;
  padding-top: 0px;
  padding-bottom: 0.25rem;
}
.shipment-price-detail__content__explanation {
  position: relative;
  background-color: $light-gray;
}
@media only screen and (max-width: $breakpoint-laptop-s) and (min-width: $breakpoint-tablet), screen and (max-width: $breakpoint-mobile-l) {
  .shipment-price-detail__content__explanation img {
    display: none;
  }
}
.shipment-price-detail__total .price-total-price-tax {
  font-style: italic;
}
.shipment-price-detail__total .price-total-price-container {
  padding: 8px;
  min-width: 110px;
}
@media only screen and (max-width: $breakpoint-laptop-l) {
  .shipment-price-detail__content {
    flex-direction: column;
  }
  .shipment-price-detail__content__explanation {
    position: relative;
    margin-left: 0 !important;
    flex: 0;
  }
}
@media only screen and (max-width: $breakpoint-laptop-s) {
  .shipment-price-detail {
    padding: 32px !important;
  }
  .shipment-price-detail__content > div {
    margin-right: 0 !important;
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .shipment-price-detail {
    padding: 16px !important;
  }
  .shipment-price-detail__content__explanation img {
    display: none;
  }
}
</style>

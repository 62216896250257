var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipment-payment-confirmation-view tw-px-4 md:tw-px-10 tw-pb-4 md:tw-pb-10 tw-pt-8 md:tw-pt-16"},[_c('shipment-payment-confirmation-view-close-button',{on:{"close":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"shipment-payment-confirmation-view__wrapper tw-flex tw-flex-col tw-mx-auto tw-text-center tw-mb-4"},[_c('img',{staticClass:"tw-mb-8 tw-mx-auto",attrs:{"src":require("@/assets/img/icons/confirmation.svg"),"width":"80","height":"80","alt":""}}),_c('h1',{staticClass:"shipment-payment-confirmation-view__title fs-22 tw-font-medium tw-mb-4 md:tw-whitespace-nowrap",domProps:{"textContent":_vm._s(_vm.$t('shipment.paragraphs.shipment_planned'))}}),_c('i18n',{staticClass:"tw-text-secondary-lighten tw-mb-6",attrs:{"path":_vm.contentKey,"tag":"p"},scopedSlots:_vm._u([{key:"email",fn:function(){return [_c('span',{staticClass:"tw-font-bold tw-whitespace-nowrap",domProps:{"textContent":_vm._s(_vm.getUserInfos.email)}})]},proxy:true}])}),_c('UiButton',{staticClass:"tw-text-white tw-px-4 tw-mx-auto",attrs:{"to":{
        name: 'Shipment',
        params: {
          uuid: _vm.shipment.uuid
        }
      },"variant":"primary"},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.track_shipment'))+" ")]),_c('div',{staticClass:"shipment-payment-confirmation-view__links tw-flex tw-justify-center"},[_c('UiLink',{staticClass:"tw-text-left tw-text-sm tw-mt-4",attrs:{"to":{
          name: 'NewShipment'
        },"standalone":""}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.new_shipment'))+" ")])],1)],1),_c('img',{staticClass:"shipment-payment-confirmation-view__illustration tw-w-full tw-pointer-events-none tw-select-none tw--mt-8 tw-hidden md:tw-block",attrs:{"src":require("@/assets/img/illustrations/shipment_confirmation.svg"),"alt":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="ctk-comment tw-flex tw-flex-col">
    <div
      v-text="$t('app.labels.comment')"
      class="group-label tw-text-secondary-text"
      :class="[labelClass]"
    />
    <div
      class="group-value"
      :class="[textClass]"
    >
      <p
        v-text="comment ? commentToShow : $t('app.values.no_comment')"
        class="tw-mb-0"
      />
      <UiLink
        v-if="isCommentLong"
        href="#"
        aria-role="button"
        data-test="read-more"
        @click.prevent="commentVisible = !commentVisible"
      >
        {{ $t(commentVisible
          ? 'app.buttons.read_less'
          : 'app.buttons.read_more') }}
      </UiLink>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'CtkComment',
    props: {
      comment: {
        type: String,
        default: null
      },
      textClass: {
        type: String,
        default: 'tw-text-blue-500'
      },
      labelClass: {
        type: String,
        default: null
      },
      truncateLength: {
        type: Number,
        default: 120
      }
    },
    data () {
      return {
        commentVisible: false
      }
    },
    computed: {
      /**
       * Returns true if we consider that the comment is too long
       * @function isCommentLong
       * @returns {boolean}
       */
      isCommentLong () {
        return !!this.comment && this.comment.length > this.truncateLength
      },
      /**
       * Returns the proposal comment, if too long, returns a cropped version
       * @function comment
       * @returns {string} comment
       */
      commentToShow () {
        return this.isCommentLong && !this.commentVisible
          ? `${this.comment.slice(0, this.truncateLength)}...`
          : this.comment
      }
    }
  })
</script>

<template>
  <!-- TODO: We don't need this useless component. Use UiLink right away -->
  <ctk-view-back-button
    :to="{
      name: 'Shipments',
      params: {
        state
      }
    }"
    :text="$t('shipment.buttons.all_shipments.state.' + state, {
      count: getShipmentsMetrics.counts
        ? $n(getShipmentsMetrics.counts[state])
        : '-'
    })"
    class="shipments-back-button"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkViewBackButton from '@/components/CtkViewBackButton/index.vue'

  /**
   * @module component - ShipmentsBackButton
   */
  export default defineComponent({
    name: 'ShipmentsBackButton',
    components: {
      CtkViewBackButton
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment',
        'getShipmentsMetrics'
      ]),
      /**
       * Returns an appropriate state for the current shipment
       * @function state
       * @returns {string}
       */
      state () {
        const { state } = this.getCurrentShipment
        let metricsState = state

        const cancelledStates = ['expired', 'cancelled']
        const inProgressStates = ['started', 'near_pickup', 'transit', 'near_delivery', 'delivered']
        const completedStates = ['finished', 'proof_of_delivery_available']

        if (cancelledStates.includes(state)) metricsState = 'cancelled'
        if (inProgressStates.includes(state)) metricsState = 'in_progress'
        if (completedStates.includes(state)) metricsState = 'completed'

        return metricsState
      }
    }
  })
</script>

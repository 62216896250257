var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipment-price-detail-booking"},[_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"tw-font-medium tw-flex-1",domProps:{"textContent":_vm._s(_vm.$t('shipment.titles.billing_carrier_booking', {
        carrier: _vm.booking && _vm.booking.driver && _vm.booking.driver.carrier ? _vm.booking.driver.carrier.name : '-'
      }))}}),_c('div',{staticClass:"tw-flex tw-justify-end tw-items-start"},[_c('PriceTotal',{attrs:{"price-excluded":_vm.$t('price_excl_tax', {
          price: _vm.$options.filters.currency(_vm.booking.billing.price, _vm.booking.billing.currency, _vm.$i18n.locale)
        }),"price-included":_vm.$t('price_incl_tax', {
          price: _vm.$options.filters.currency(_vm.booking.billing.vat_included_price, _vm.booking.billing.currency, _vm.$i18n.locale)
        })}}),_c('UiButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"tw-ml-2",attrs:{"title":_vm.isOpen
          ? _vm.$t('shipment.labels.hide_price_detail')
          : _vm.$t('shipment.labels.show_price_detail'),"variant":"link"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('UiMaterialIcon',{staticClass:"tw-transition-transform",class:{
              'tw-transform tw-rotate-180': _vm.isOpen
            },attrs:{"name":"keyboard_arrow_down"}})]},proxy:true}])})],1)]),_c('BCollapse',{staticClass:"mt-2",attrs:{"id":"collapse"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[(_vm.booking.billing.lines)?_c('PricingLayout',{staticClass:"mb-2"},_vm._l((_vm.booking.billing.lines),function(line){return _c('PricingLines',{key:line.key,attrs:{"label":line.label,"cancelled":line.is_cancelled,"comment":line.comment}},[_c('span',{staticClass:"tw-text-blue-500"},[_vm._v(" "+_vm._s(_vm._f("currency")(line.price,_vm.booking.billing.currency, _vm.$i18n.locale))+" ")])])}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
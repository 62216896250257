<template>
  <div class="shipment-payment-content tw-text-base tw-font-medium">
    <div class="md:tw-w-2/3">
      <p
        v-text="$t('shipment.paragraphs.awaiting_payment')"
        data-test="status"
      />
      <i18n
        v-if="!isInProgress"
        path="shipment.paragraphs.payment_before_max_date"
        tag="p"
        class="tw-mb-0"
        data-test="max-payment-date"
      >
        <template #date>
          <span
            class="tw-whitespace-nowrap"
            v-text="$moment(getCurrentShipment.billing.max_payment_date).format('LLL')"
            data-test="date"
          />
        </template>
      </i18n>

      <ui-button
        class="tw-px-4 tw-flex tw-items-center tw-text-white tw-truncate tw-my-auto tw-mt-8"
        type="button"
        variant="primary"
        data-test="button"
        @click="pay"
      >
        {{ $t('shipment.buttons.pay') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'

  /**
   * Textual status content when the shipment requires a payment
   * @module component - ShipmentPaymentContent
   */
  export default {
    name: 'ShipmentPaymentContent',
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if the shipment is in progress
       * @function isInProgress
       * @returns {boolean}
       */
      isInProgress () {
        const { state } = this.getCurrentShipment
        const states = ['started', 'transit', 'near_delivery', 'delivered']
        return states.includes(state)
      }
    },
    methods: {
      pay () {
        EventBus.$emit('shipment:open-dialog', 'payment')
      }
    }
  }
</script>

<style lang="scss" scoped>
@media (min-width: 770px) {
  .shipment-payment-content::after {
    position: absolute;
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-image: url('~@/assets/img/illustrations/tracking-planned.svg');
    bottom: 0;
    right: 32px;
    width: 30%;
    min-height: 150px;
  }
}
</style>

<template>
  <section
    id="carrier-section"
    class="shipment-carrier-detail tw-p-4 md:tw-p-8 lg:tw-p-12 tw-bg-white border-gray tw-rounded"
  >
    <div class="shipment-carrier-detail__content tw-flex tw-items-start tw-flex-col tw-items-stretch md:tw-flex-row md:tw-justify-between">
      <div class="shipment-carrier-detail__carrier__wrapper tw-flex tw-items-start tw-flex-col md:tw-w-1/2">
        <h4
          class="tw-mb-6"
          v-text="$t('shipment.titles.carrier_detail')"
        />
        <div class="shipment-carrier-detail__carrier tw-mr-6">
          <div
            class="carrier-name tw-font-medium tw-mb-2"
            v-text="carrier.name"
          />
          <ctk-address
            :address="carrier.address"
            class="tw-text-gray-700 tw-mb-2"
          />
          <p
            v-if="carrier.vat_number"
            data-test="vat_number"
            class="tw-text-gray-700"
            v-text="`${$t('shipment.labels.vat_number')} ${carrier.vat_number}`"
          />
        </div>
      </div>
      <div
        :class="{
          'shipment-carrier-detail__driver--pallet-network': getCurrentShipment.price_type === 'pallet_network'
        }"
        class="shipment-carrier-detail__driver tw-relative tw-mt-4 md:tw-mt-0 tw-px-5 tw-py-0 md:tw-pl-10 xl:tw-px-6 tw-flex-grow"
      >
        <template>
          <img
            src="@/assets/img/logo_bg_white.svg"
            height="27"
            width="140"
            alt=""
            class="tw-mb-3"
            data-test="logo"
          >
          <div
            class="tw-text-base tw-font-medium"
            v-text="$t('shipment.labels.driver_pallet_network_question')"
            data-test="label"
          />
          <div
            class="tw-text-sm tw-font-normal tw-text-gray-700"
            v-text="$t('shipment.paragraphs.driver_pallet_network_question')"
            data-test="sub"
          />
          <ui-button
            v-if="contactFormLink"
            :href="contactFormLink"
            outline
            size="sm"
            variant="info"
            rel="noopener"
            target="_blank"
            class="shipment-carrier-detail__driver__btn"
            data-test="link"
          >
            {{ $t('shipments.buttons.driver_pallet_network_question') }}
          </ui-button>
        </template>
      </div>
    </div>

    <div class="shipment-carrier-detail__bookings tw-mt-6">
      <div
        v-if="$wait.is('loading shipment bookings')"
        data-test="skeleton"
      >
        <ctk-skeleton-block
          width="320px"
          height="25px"
          level="1"
          loading
          class="tw-mb-4"
        />
        <ctk-skeleton-view
          depth="1"
          class="tw-flex tw-justify-between tw-mb-2"
        >
          <ctk-skeleton-block
            width="200px"
            height="25px"
            level="2"
            loading
          />
          <ctk-skeleton-block
            width="80px"
            height="25px"
            level="2"
            loading
          />
        </ctk-skeleton-view>
        <ctk-skeleton-view
          depth="2"
          class="tw-flex tw-justify-between tw-mb-2"
        >
          <ctk-skeleton-block
            width="180px"
            height="25px"
            level="2"
            loading
          />
          <ctk-skeleton-block
            width="100px"
            height="25px"
            level="2"
            loading
          />
        </ctk-skeleton-view>
        <ctk-skeleton-view
          depth="3"
          class="tw-flex tw-justify-between tw-mb-2"
        >
          <ctk-skeleton-block
            width="140px"
            height="25px"
            level="2"
            loading
          />
          <ctk-skeleton-block
            width="70px"
            height="25px"
            level="2"
            loading
          />
        </ctk-skeleton-view>
      </div>
      <shipment-bookings
        v-else-if="bookings && bookings.length > 0"
        :bookings="bookings"
      />
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { forms } from '@/../config/chronoinfos'

  import CtkAddress from '@/components/CtkAddress'
  import ShipmentBookings from './_subs/ShipmentBookings'
  import CtkSkeletonView from '@/components/CtkSkeletonView'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock'
  import Driver from '@/models/Driver'

  /**
   * @module component - shipmentCarrierDetail
   */
  export default {
    name: 'ShipmentCarrierDetail',
    components: {
      CtkAddress,
      ShipmentBookings,
      CtkSkeletonView,
      CtkSkeletonBlock
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getCid',
        'getUserInfos',
        'isImpersonating'
      ]),
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * @function carrier
       * @returns {object}
       */
      carrier () {
        return this.getCurrentShipment.mission.driver.carrier
      },
      /**
       * @function driver
       * @returns {Driver}
       */
      driver () {
        return new Driver(this.getCurrentShipment.mission.driver)
      },
      /**
       * Returns the driver phone driver, fallback to the carrier phone number
       * if not available.
       * @function phone
       * @returns {string}
       */
      phone () {
        return this.driver.phone || this.carrier.phone
      },
      /**
       * Returns the list of bookings without the current mission
       * @function bookings
       * @returns {Array<object>}
       */
      bookings () {
        const { mission, bookings } = this.getCurrentShipment
        return bookings
          ? bookings.filter(({ uuid }) => uuid !== mission.uuid)
          : []
      },
      contactFormLink () {
        const { contact: { shipment: shipmentContactForm } } = forms
        const isFrenchShipper = !this.isImpersonating && this.isUserShipper && this.getUserInfos.locale.slice(0, 2) === 'fr'
        const contactFormLocale = isFrenchShipper ? 'fr' : 'default'

        return shipmentContactForm[contactFormLocale]
      }
    },
    methods: {
      ...mapActions('shipments', [
        'setShipmentData'
      ])
    }
  }
</script>

<style lang="scss" scoped>
.shipment-carrier-detail {
  border: 1px solid $divider;
}
.shipment-carrier-detail__driver__btn {
  margin-top: 1rem;
}
.shipment-carrier-detail__bookings .ui-loader {
  margin-top: 32px;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .shipment-carrier-detail__carrier__wrapper {
    border-right: 1px solid #D8D8D8;
  }
}
@media only screen and (max-width: $breakpoint-laptop-s) {
  .shipment-carrier-detail__driver::after {
    display: none;
  }
}
</style>

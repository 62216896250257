<template>
  <nav
    :class="{
      'has-back': hasBack
    }"
    class="shipment-navigation tw-w-full tw-bg-white"
  >
    <div class="tw-flex ctk-container">
      <div
        v-if="hasBack"
        class="tw-flex shipment-navigation__back tw-mr-4"
      >
        <ShipmentsBackButton />
      </div>
      <scrollactive
        :offset="100"
        :click-to-scroll="true"
        :class="{
          'has-back': hasBack
        }"
        class="tw-flex-1 shipment-navigation__scrollable"
      >
        <ul class="tw-flex">
          <li
            v-for="(link, k) in links"
            :key="k"
            data-test="nav-item"
          >
            <UiLink
              :href="`#${link.anchor}`"
              :data-test="`nav-link-${link.anchor}`"
              standalone
              class="shipment-navigation__item tw-mr-2 scrollactive-item"
            >
              {{ link.title }}
            </UiLink>
          </li>
        </ul>
      </scrollactive>
    </div>
  </nav>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import ShipmentsBackButton from './../ShipmentsBackButton/index.vue'

  /**
   * @module component - shipmentNavigation
   * @param {boolean} [hasBack=false]
   * @param {boolean} [hasBilling=false]
   */
  export default defineComponent({
    name: 'ShipmentNavigation',
    components: {
      ShipmentsBackButton
    },
    props: {
      hasBack: {
        type: Boolean,
        default: false
      },
      hasBilling: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * @function isCorridor
       * @returns {boolean}
       */
      isCorridor () {
        return this.getCurrentShipment.price_type === 'corridor'
      },
      /**
       * @function links
       * @returns {Array<{ anchor: string, title: any }>}
       */
      links () {
        const { mission, state } = this.getCurrentShipment

        return [
          ...state === 'available' && !this.isCorridor ? [
            {
              anchor: 'proposals-section',
              title: this.$t('shipment.titles.proposals')
            }
          ] : [],
          ...mission ? [
            {
              anchor: 'carrier-section',
              title: this.$t('shipment.titles.carrier_detail')
            }
          ] : [],
          {
            anchor: 'references-section',
            title: this.$t('shipment.titles.references_detail')
          },
          {
            anchor: 'price-section',
            title: this.$t('shipment.titles.price_detail')
          },
          ...this.hasBilling ? [
            {
              anchor: 'billing-section',
              title: this.$t('shipment.titles.billing')
            }
          ] : [],
          {
            anchor: 'transport-section',
            title: this.$t('shipment.titles.transport_detail')
          },
          {
            anchor: 'load-section',
            title: this.$t('shipment.titles.load_detail')
          }
        ]
      }
    }
  })
</script>

<style lang="scss" scoped>
.shipment-navigation {
  border: 1px solid $divider;
}
.shipment-navigation ul, .shipment-navigation li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.shipment-navigation ul, .shipment-navigation__scrollable {
  overflow-x: auto;
}
.shipment-navigation ul.has-back, .shipment-navigation__scrollable.has-back {
  padding: 0 16px;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .shipment-navigation ul, .shipment-navigation__scrollable {
    padding: 0 16px;
  }
}
.shipment-navigation__item {
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  height: 53px;
  line-height: 53px;
  transition: border-bottom 200ms ease;
  border-bottom: 0 solid $divider;
}
.shipment-navigation__item.is-active {
  border-bottom: 4px solid $info;
}
.shipment-navigation__item:hover:not(.is-active) {
  border-bottom: 2px solid $divider;
}
.shipment-navigation__back {
  flex: 0 1 350px;
  width: 350px;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .shipment-navigation__back {
    display: none;
  }
}
.shipment-navigation.has-back {
  border: none;
  box-shadow: 0 1px 8px rgba(black, 0.15);
}
.shipment-navigation, .shipment-navigation__back, .shipment-navigation.has-back .shipment-navigation__item {
  height: 55px;
}
</style>

<template>
  <div
    class="tw-flex py-2 shipment-item-proposals-item pr-3"
    :class="{
      compact,
      'tw-flex-col': compact,
      'is-pending': isProposalPending,
      'is-archived': !isProposalPending
    }"
  >
    <div
      v-if="compact"
      class="tw-flex tw-justify-between shipment-item-proposals-item__header-compacted mb-2"
    >
      <shipment-item-proposals-item-status
        v-if="!isProposalPending"
        :proposal="proposal"
      />
      <shipment-item-proposals-item-expiration
        v-if="isProposalPending"
        class="pr-3"
        :proposal="proposal"
      />
      <shipment-proposals-actions
        v-if="isProposalPending"
        :proposal="proposal"
        :shipment="shipment"
        class="flex-fixed"
      />
    </div>

    <shipment-item-proposals-item-status
      v-if="!compact && !isProposalPending"
      :proposal="proposal"
    />

    <shipment-item-proposals-item-expiration
      v-if="!compact && isProposalPending"
      class="pr-3"
      :proposal="proposal"
    />

    <shipment-item-proposals-item-dates-price
      :currency="shipment.offer_price.currency"
      :proposal="proposal"
    />

    <ctk-comment
      label-class="fs-13"
      class="shipment-item-proposals-item__group shipment-item-proposals-item__comment pr-3 tw-flex-1"
      :comment="proposal.comment"
    />

    <div
      v-if="!compact && isProposalPending"
      class="shipment-item-proposals-item__actions tw-flex"
    >
      <shipment-proposals-actions
        :proposal="proposal"
        :shipment="shipment"
      />
    </div>
  </div>
</template>

<script>
  import CtkComment from '@/components/CtkComment'
  import ShipmentProposalsActions from '@/views/Shippers/Shipments/components/ShipmentProposalsActions'
  import ShipmentItemProposalsItemDatesPrice from './_subs/ShipmentItemProposalsItemDatesPrice'
  import ShipmentItemProposalsItemExpiration from './_subs/ShipmentItemProposalsItemExpiration'
  import ShipmentItemProposalsItemStatus from './_subs/ShipmentItemProposalsItemStatus'

  /**
   * @module component - shipmentItemProposalsItem
   * @param {object} proposal
   * @param {object} shipment
   * @param {boolean} compact - Specify if the layout is compact
   */
  export default {
    name: 'ShipmentItemProposalsItem',
    components: {
      ShipmentProposalsActions,
      ShipmentItemProposalsItemDatesPrice,
      ShipmentItemProposalsItemExpiration,
      ShipmentItemProposalsItemStatus,
      CtkComment
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        required: true
      },
      compact: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      /**
       * Returns true if the proposal is pending
       * @function isProposalPending
       * @returns {boolean}
       */
      isProposalPending () {
        return this.proposal.status === 'pending'
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-proposals-item {
    position: relative;
    border-radius: 4px 0 0 4px;
    background-color: #F9F9F9;

    &.is-pending {
      border-left: 4px solid $background-pending;
    }

    &.is-archived {
      border-left: 4px solid $background-danger;
    }

    &__group {
      position: relative;

      .group-label {
        color: $secondary-text;
      }

      &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 20px;
        top: 10px;
        left: -16px;
        border: 1px solid $third-text;
        border-right: none;
      }
    }

    &__actions {
      @media only screen and (max-width: $breakpoint-laptop-s) {
        position: absolute;
        top: -14px;
        right: 0;
      }

      .shipment-proposals-actions {
        margin: auto 0;
      }
    }

    .shipment-item-proposals-item-dates-price {
      flex: 1.5;

      @media only screen and (max-width: $breakpoint-tablet) {
        padding-left: 32px !important;
        min-width: 100%;
      }
    }

    &__header-compacted {
      @media only screen and (max-width: $breakpoint-laptop-s) {
        flex-direction: column;
        padding: 0 16px;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        flex-direction: row;
      }

      @media only screen and (max-width: $breakpoint-mobile-l) {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      .shipment-item-proposals-item__comment {
        min-width: 100%;
        margin-left: 32px !important;
      }
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      flex-wrap: wrap;
      min-width: 100%;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      min-width: auto;
      flex: 1;
    }

    &.compact {
      .shipment-proposals-actions {
        margin-top: 7px;
      }

      .shipment-item-proposals-item-dates-price,
      .shipment-item-proposals-item__comment {
        @media only screen and (min-width: $breakpoint-laptop-s) {
          margin-left: 30px;
        }
      }

      .shipment-item-proposals-item__comment {
        width: 100%;

        @media only screen and (min-width: $breakpoint-laptop-s) {
          width: 50%;
        }
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        .shipment-item-proposals-item-expiration {
          flex: 1;
        }
      }

      @media only screen and (max-width: $breakpoint-laptop-s) {
        .shipment-item-proposals-item__comment {
          margin-left: 30px;
          padding-left: 0 !important;
          padding-right: 60px;
        }
      }
    }
  }

</style>

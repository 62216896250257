<template>
  <UiLink
    class="shipment-update-phone-link tw-text-sm tw-truncate"
    href="javascript:void(0)"
    standalone
    @click.stop="openDialog"
  >
    {{ $t('edit') | capitalize }}
  </UiLink>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { EventBus } from '@/services/EventBus'

  /**
   * Component to show a the edit link of a shipment phone number
   * It calls an EventBus event on click.
   * @module component - ShipmentUpdatePhoneLink
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'ShipmentUpdatePhoneLink',
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    methods: {
      openDialog () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Phone Number Change', this.shipment.uuid)
        }

        EventBus.$emit('shipment:open-dialog', 'phone')
      }
    }
  })
</script>

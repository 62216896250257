<template>
  <div class="shipment-summary-actions tw-pt-6">
    <ui-button
      :rounded="false"
      variant="info"
      outline
      class="tw-w-full"
      data-test="duplicate"
      @click="duplicate(getCurrentShipment)"
    >
      {{ $t('shipment.buttons.duplicate') | capitalize }}
    </ui-button>

    <ui-button
      v-if="canBeCancelled"
      :rounded="false"
      variant="danger"
      outline
      type="button"
      class="tw-w-full tw-mt-2"
      data-test="cancel"
      @click="cancel"
    >
      {{ $t('shipment.buttons.cancel') }}
    </ui-button>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'
  import Hotjar from '@/plugins/VueHotjar'

  import useRouter from '@/composables/useRouter'
  import useDuplicateShipment from '@/composables/shipments/useDuplicateShipment'

  /**
   * @module component - shipmentSummaryActions
   */
  export default defineComponent({
    name: 'ShipmentSummaryActions',
    setup () {
      const router = useRouter()

      const duplicate = (shipment) => {
        useDuplicateShipment(shipment)

        router.value.push({
          name: 'NewShipmentAddress',
          params: {
            direction: 'pickup'
          }
        })
          .catch(() => {})
      }

      return {
        duplicate
      }
    },
    methods: {
      /**
       * Open the shipment cancellation dialog
       * @function cancel
       */
      cancel () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Cancellation', this.getCurrentShipment.uuid)
        }

        Hotjar.tag('Shipment Initiated Cancellation')

        EventBus.$emit('shipment:open-dialog', 'cancellation')
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if the shipment can be cancelled, according to it's state
       * @function canBeCancelled
       * @returns {boolean}
       */
      canBeCancelled () {
        const states = ['available', 'planned', 'started', 'transit', 'near_delivery']
        return states.includes(this.getCurrentShipment.state)
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-summary-actions {
    border-top: 1px solid $divider;
  }

</style>

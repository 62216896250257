<template>
  <section
    id="load-section"
    class="shipment-load-detail tw-p-12 tw-bg-white tw-border tw-border-solid tw-border-gray-300 tw-rounded"
  >
    <h4
      class="tw-mb-6 tw-text-2xl"
      data-test="title"
      v-text="$t('shipment.titles.load_detail')"
    />

    <div class="shipment-load-detail__items tw-flex tw-flex-wrap tw-mb-6">
      <!-- Load type -->
      <offer-group-item
        :title="$t('app.labels.load.type') | capitalize"
        data-test="type"
      >
        <template
          v-if="getCurrentShipment.load.type === 'custom'"
        >
          {{ $t('prepared_goods') | capitalize }}
        </template>
        <template
          v-else-if="getCurrentShipment.load.type === 'pallets'"
        >
          {{ $t('pallets') | capitalize }}
        </template>
        <template
          v-else
        >
          {{ $t('unprepared_goods') | capitalize }}
        </template>
      </offer-group-item>

      <!-- MPL or format -->
      <offer-group-item
        v-if="shouldDisplayMpl"
        :title="$t('app.labels.load.mpl') | capitalize"
        data-test="format"
      >
        <template>
          {{ $t('lm', { lm: loadLmValue }) }}
        </template>
      </offer-group-item>
      <offer-group-item
        v-else
        :title="$t('app.labels.load.format') | capitalize"
        data-test="format"
      >
        <template
          v-if="getCurrentShipment.load.type === 'pallets'"
        >
          {{ getCurrentShipment.load.format }} cm
        </template>
        <template
          v-else
        >
          {{ `${getCurrentShipment.load.width}x${getCurrentShipment.load.length} cm` }}
        </template>
      </offer-group-item>

      <!-- Quantity -->
      <offer-group-item
        v-if="getCurrentShipment.load.type === 'pallets'"
        :title="$t('app.labels.load.quantity') | capitalize"
        data-test="quantity"
      >
        {{ $n(getCurrentShipment.load.quantity) }}
      </offer-group-item>
      <offer-group-item
        :title="$t('app.labels.load.max_height') | capitalize"
        data-test="height"
      >
        <span
          v-text="`${$n(getCurrentShipment.load.height)} cm`"
        />
      </offer-group-item>

      <!-- Weight -->
      <offer-group-item
        :title="$t('app.labels.load.weight') | capitalize"
        data-test="weight"
      >
        <span
          v-text="$t('weight_unit', {
            weight: $n(getCurrentShipment.load.weight)
          })"
        />
      </offer-group-item>

      <!-- Insured load value -->
      <offer-group-item
        v-if="!!getCurrentShipment.insured_load_value"
        :title="$t('app.labels.load.insured_load_value') | capitalize"
        data-test="insured-load-value"
      >
        <span class="payment-item__price__inline">
          {{ getCurrentShipment.insured_load_value | currency(getCurrentShipment.offer_price.currency, $i18n.locale, true) }}
        </span>
      </offer-group-item>
    </div>

    <!-- Description -->
    <offer-specificity-group
      v-if="getCurrentShipment.load.description"
      class="lg:tw-max-w-80 tw-mt-6"
      data-test="load-group"
    >
      <offer-specificity-item
        v-if="getCurrentShipment.load.description"
        :icon="'comment'"
        :title="$t('comment')"
        :content="getCurrentShipment.load.description"
        data-test="description"
      />
    </offer-specificity-group>

    <!-- Pallets -->
    <offer-group-item
      v-if="hasPallets"
      :title="$t('app.labels.load.pallets_detail')"
      class="tw-mt-4"
      data-test="pallets"
    >
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-flex-wrap">
        <offer-load-pallet
          v-for="(pallet, k) in getCurrentShipment.load.pallets"
          :key="k"
          :pallet="pallet"
          class="md:not-last:tw-mr-3 tw-mb-3"
          data-test="pallet"
        />
      </div>
    </offer-group-item>
  </section>
</template>

<script>
  import { computed, defineComponent } from '@vue/composition-api'

  import OfferGroupItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferGroupItem/index.vue'
  import OfferLoadPallet from '@/views/Carriers/Offers/components/OfferDetail/components/OfferLoadSection/_subs/OfferLoadPallet/index.vue'
  import OfferSpecificityGroup from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/index.vue'
  import OfferSpecificityItem from '@/views/Carriers/Offers/components/OfferDetail/components/OfferSpecificityGroup/_subs/OfferSpecificityItem/index.vue'
  import useStore from '@/composables/useStore'

  /**
   * @module component - ShipmentLoadDetail
   */
  export default defineComponent({
    name: 'ShipmentLoadDetail',
    components: {
      OfferGroupItem,
      OfferLoadPallet,
      OfferSpecificityGroup,
      OfferSpecificityItem
    },
    setup () {
      const store = useStore()

      const getCurrentShipment = computed(() => store.value.getters['shipments/getCurrentShipment'])

      /** @type {import('@vue/composition-api').ComputedRef<boolean>} */
      const hasPallets = computed(() => {
        const shipment = getCurrentShipment.value
        return !!shipment && !!shipment.load && !!shipment.load.pallets && !!shipment.load.pallets.length
      })

      const loadLmValue = computed(() => {
        const shipment = getCurrentShipment.value
        const shipmentLinearMeters = !!shipment && !!shipment.load && shipment.load.linear_meters
        return shipmentLinearMeters || null
      })

      const shouldDisplayMpl = computed(() => {
        const shipment = getCurrentShipment.value
        const isCustomShipment = !!shipment && !!shipment.load && !!shipment.load.type && shipment.load.type === 'custom'
        const isMultiPallet = hasPallets.value ? shipment.load.pallets.length > 1 : false

        return isCustomShipment && isMultiPallet && !!loadLmValue
      })

      return {
        hasPallets,
        getCurrentShipment,
        shouldDisplayMpl,
        loadLmValue
      }
    }
  })
</script>

<style lang="scss">
@media only screen and (max-width: $breakpoint-laptop-s) {
  .shipment-load-detail {
    padding: 32px !important;
  }
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .shipment-load-detail {
    padding: 16px !important;
  }
}
@media only screen and (max-width: $breakpoint-tablet) {
  .shipment-load-detail__content {
    flex-direction: column;
  }
  .shipment-load-detail .shipment-description-container {
    margin-top: 16px;
  }
}
.shipment-load-detail__items {
  margin: -1rem;
}
.shipment-load-detail__items .offer-group-item {
  margin-bottom: 0.5rem;
  margin: 1rem;
  flex: 1 0 calc(45% - 1rem);
}
@media (min-width: 770px) {
  .shipment-load-detail__items .offer-group-item {
    flex: 1 0 calc(15% - 1rem);
  }
  .shipment-load-detail__items .offer-group-item:not(:last-child) {
    position: relative;
  }
  .shipment-load-detail__items .offer-group-item:not(:last-child)::after {
    --tw-border-opacity: 1;
    border-color: rgba(214, 214, 218, var(--tw-border-opacity));
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 0px;
    height: 100%;
    position: absolute;
    content: '';
    width: 1px;
    right: -1rem;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
@media only screen and (min-width: $breakpoint-tablet) {
  .shipment-load-detail {
    position: relative;
    padding-bottom: 164px !important;
  }
  .shipment-load-detail::after {
    content: '';
    position: absolute;
    width: 470px;
    max-width: 100%;
    height: 160px;
    bottom: 0;
    right: 0;
    background-image: url('~@/assets/img/illustrations/goods.svg');
    background-size: cover;
    background-position: bottom right;
  }
}
</style>
